import { useState } from "react";
import Link from "next/link";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
export const classNameDropdownMenuItem =
  "flex gap-x-3 items-center justify-between  cursor-pointer hover:bg-gray-100  ";

export const CustomMenu = ({ items }: any) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleItemClick = (item: any) => {
    if (item.onClick) {
      item.onClick();
    }
    setDropdownOpen(false);
  };

  return (
    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenuTrigger>
        <DotsVerticalIcon width={18} height={18} />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex flex-col p-2  mr-6 min-w-60 min-w- text-gray-700 border border-gray-300  ">
        {items.map((item: any, index: any) => (
          <DropdownMenuItem
            key={index}
            className={classNameDropdownMenuItem}
            onClick={() => handleItemClick(item)}
          >
            <div>{item.label}</div>
            {item.icon}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

{
  /* {items.map((item: any, index: any) => (
          <DropdownMenuItem key={index} onClick={() => handleItemClick(item)} className={classNameDropdownMenuItem}>
            {item.link ? (
              <Link href={item.link}>
                <div className={classNameDropdownMenuItem}>
                  <div> {item.label}</div>

                  {item.icon}
                </div>
              </Link>
            ) : (
              <button className={classNameDropdownMenuItem}>
                <div className={classNameDropdownMenuItem}>
                  <div> {item.label}</div>
                  {item.icon}
                </div>
              </button>
            )}
          </DropdownMenuItem>
        ))} */
}

{
  /* <div className="border-t border-gray-200 my-2"></div> */
}
