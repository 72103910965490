import Image from "next/image";
import Link from "next/link";
import { cn } from "@lib/utils";

const Logo = ({
  href,
  size = 200, // Optional size prop to control container width
  lightBackground = false,
}: any) => {
  return (
    <div
      className={cn("relative")} // Set position relative directly through className
      style={{
        width: size,
        height: size / 2, // Set an appropriate height based on the aspect ratio
      }}
    >
      <Link href={href}>
        <Image
          src={
            lightBackground
              ? "/images/logo/logo-white.png"
              : "/images/logo/logo.png"
          }
          alt="BusinessForecast.com logo"
          fill
          sizes={`${size}px`} // Set sizes based on width
          priority
          style={{ objectFit: "contain" }}
        />
      </Link>
    </div>
  );
};

export default Logo;
