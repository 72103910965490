"use client";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import Link from "next/link";
import Image from "next/image";
import { signOut } from "next-auth/react";

import { useSession, getProviders } from "next-auth/react";

import { useEffect, useState, useMemo } from "react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import { generateInitials } from "@lib/utils";

import { useUserInput } from "@providers/UserInputProvider";

import {
  AvatarIcon,
  ExitIcon,
  BoxIcon,
  DashboardIcon,
} from "@radix-ui/react-icons";
import { useRouter } from "next/navigation";
import { classNameDropdownMenuItem } from "@components/Menu/CustomMenu";

export const ProfileMenu = () => {
  const { userData, userDataIsLoading, companyData } = useUserInput() as any;
  const { subscriptionData, subscriptionDataIsLoading } = useUserInput() as any;

  const router = useRouter();

  const handleSignOut = async () => {
    await signOut({
      callbackUrl: process.env.NEXT_PUBLIC_BASE_URL || "",
      redirect: true,
    });
  };

  const { data: session } = useSession();

  const userId = userData?._id;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const iconSize = 17;

  // useEffect(() => {
  //   if (!userId) {
  //     router.push("/");
  //   }
  // }, [userId, router]);

  return (
    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenuTrigger>
        <div className="-m-1.5 flex items-center   cursor-pointer">
          <span className="sr-only">Open user menu</span>

          <Avatar className=" border-2 border-main   ">
            <AvatarFallback className="bg-gray-700 text-white hover:text-gray-300">
              {generateInitials(session?.user?.name || "")}
            </AvatarFallback>
          </Avatar>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="flex flex-col p-2  mr-6 min-w-60 min-w- text-gray-700 border border-gray-300  ">
        <div className="text-center mx-auto">
          <DropdownMenuItem className="bg-transparent text-current hover:bg-transparent hover:text-current focus:bg-transparent focus:text-current">
            <div className="">{session?.user?.email}</div>
          </DropdownMenuItem>
          {subscriptionData?.status === "active" && (
            <DropdownMenuItem className="bg-transparent text-current hover:bg-transparent hover:text-current focus:bg-transparent focus:text-current">
              <div className="  text-xxs bg-main px-2 py-0 rounded-full text-white mx-auto">
                Pro plan
              </div>
            </DropdownMenuItem>
          )}
        </div>

        <div className="border-t border-gray-200 my-2"></div>

        <Link href={`/loading`}>
          <DropdownMenuItem
            onClick={() => setDropdownOpen(false)}
            className={classNameDropdownMenuItem}
          >
            <div>Dashboard</div>
            <DashboardIcon width={iconSize} height={iconSize} />
          </DropdownMenuItem>
        </Link>

        {userId && (
          <Link href={`/user/${userId}`}>
            <DropdownMenuItem
              onClick={() => setDropdownOpen(false)}
              className={classNameDropdownMenuItem}
            >
              <div>Profile</div>
              <AvatarIcon width={iconSize} height={iconSize} />
            </DropdownMenuItem>
          </Link>
        )}

        <div className="border-t border-gray-200 my-2"></div>

        <button onClick={handleSignOut}>
          <DropdownMenuItem className={classNameDropdownMenuItem}>
            <div>Sign Out</div>

            <ExitIcon width={iconSize} height={iconSize} />
          </DropdownMenuItem>
        </button>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
