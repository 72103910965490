import Link from "next/link";
import { cn } from "@lib/utils";
import { DividerHorizontalIcon } from "@radix-ui/react-icons";

const LinkItem = ({
  href,
  isSelected,
  label,
  icon,
  incomplete = false,
  onClick,
  className,
}: {
  href?: string;
  isSelected?: boolean;
  label: string;
  icon?: any;
  incomplete?: boolean;
  onClick?: () => void;
  className?: string;
}) => {
  if (href) {
    // If href is provided, use the Link component
    return (
      <Link
        href={href}
        className={cn(
          "group flex items-center w-full hover:bg-main font-light py-2 ",
          isSelected ? "text-main hover:bg-mainDark font-bold" : "",
          className
        )}
        onClick={onClick}
      >
        <div className="flex items-center gap-3">
          <div
            className={cn("text-main", !isSelected && "group-hover:text-white")}
          >
            {icon}
          </div>
          <span className="font-light">{label}</span>
        </div>

        {incomplete && (
          <div className="bg-yellow-600 text-xxs px-2 py-0.5 ml-3 text-white font-light rounded-2xl group-hover:text-white hover:!text-white">
            Incomplete
          </div>
        )}
      </Link>
    );
  }

  return (
    <div
      className={cn(
        "group flex items-center w-full hover:bg-main font-light py-2",
        isSelected ? "text-main hover:bg-mainDark underline" : "",
        className
      )}
      onClick={onClick}
      role="button"
      // tabIndex={0}
    >
      <div className="flex items-center gap-3">
        <div className="text-main group-hover:text-white">{icon}</div>
        <span className="font-light">{label}</span>
      </div>

      {incomplete && (
        <div className="bg-yellow-600 text-xxs px-2 py-0.5 ml-3 text-white font-light rounded-2xl group-hover:text-white hover:!text-white">
          Incomplete
        </div>
      )}
    </div>
  );
};

export default LinkItem;
