import { FeatureProps } from "@interfaces/features";
import { TokensIcon } from "@radix-ui/react-icons";
import { BarChartIcon } from "@radix-ui/react-icons";

export const forecastPlatform: FeatureProps = {
  url: "/platform/forecast",
  title: "Forecast",
  tagline: "Set up a fully integrated P&L, BS and CF forecast of your business",
  icon: BarChartIcon,

  header: {
    title: "Business forecasting is essential for financial planning",
    description:
      "Even though the future is inherently uncertain, there is great value in forecasting and setting boundaries on expected results based on reasonable assumptions.",
    image: {
      src: "/images/illustration/full-financial-forecast.svg",
      alt: "Generic illustration",
      positionRight: true,
    },
    ctaButton: true,
  },

  features: [
    {
      title: "Choose from a variety of forecast methods",
      description:
        "To forecast a line item, choose a method from our forecast library. It includes expertly crafted methodologies for revenue, expenses, assets, and financing items.",

      image: {
        src: "/images/screenshots/forecast/forecastMethods.png",
        alt: "Screenshot of forecast methods",
        positionRight: false,
      },
    },

    {
      title: "Built in sanity checks",
      description:
        "Utilize platform feedback to enhance forecast accuracy and internal consistency. A wide range of sanity checks are included ranging from basic checks like whether cash is negative at any point to more advanced checks. ",
      image: {
        src: "/images/screenshots/forecast/sanityChecks.png",
        alt: "Screenshot of built in sanity checks",
        positionRight: true,
      },
    },

    {
      title: "Scenario analysis",
      description:
        "Generate low, base, and high scenarios to evaluate potential outcomes under different conditions. Creating a “what if” scenario is especially useful in liquidty restricted business environments.   ",
      image: {
        src: "/images/screenshots/forecast/scenarioAnalysis.png",
        alt: "Screenshot of scenario analysis",
        positionRight: false,
      },
    },

    {
      title: "Insightful financial outputs",
      description:
        "Explore and analyze results with various useful financial ratios, financial KPIs and dashboards. ",
      image: {
        src: "/images/screenshots/forecast/forecastOutputs.png",
        alt: "Screenshot of insightful financial outputs",
        positionRight: true,
      },
    },
  ],

  // {
  //   title: "No more unnecessarily complicated spreadsheets",
  //   description:
  //     "Easily create a tailored forecast for your business. Our software is flexible, user-friendly, and minimizes errors.",
  //   image: {
  //     src: "/images/features/screenshot/full-financial-forecast/BS.png",
  //     alt: "TBD",
  //     positionRight: false,
  //   },
  // },

  // faq: [
  //   {
  //     question: "Why is financial forecasting useful?",
  //     answer:
  //       "Financial forecasting is crucial for budgeting, planning, and strategic decision-making. It can also be used as the basis for a business valuation",
  //   },
  //   {
  //     question: "How accurate are financial forecasts?",
  //     answer:
  //       "The accuracy of financial forecasts depends on the quality of the data and assumptions used. Regular updates, revisions and third-party insights enhance accuracy.",
  //   },
  // ],
};

export const valuationPlatform: FeatureProps = {
  url: "/platform/valuation",
  tagline: "Perform a discounted cash flow valuation of your business",
  title: "Valuation",
  icon: TokensIcon,

  header: {
    title:
      "A well-crafted business forecast is the backbone of any credible valuation",
    description:
      "Understanding the value of your business is crucial for increasing its value and for determining the right price when buying or selling.",
    image: {
      src: "/images/illustration/business-valuation.svg",
      alt: "Generic illustration",
      positionRight: true,
    },
    ctaButton: true,
  },

  features: [
    {
      title: "Discounted cash flow valuation",
      description:
        "The discounted cash flow (DCF) valuation is the cornerstone of valuation and it relies heavily on a solid business forecast. It estimates intrinsic value by adjusting expected cash flows for inflation and risk.",
      image: {
        src: "/images/screenshots/valuation/dcfValuation.png",
        alt: "Screenshot of DCF valuation",
        positionRight: false,
      },
      ctaButton: false,
    },
    {
      title: "Discount rate estimation",
      description:
        "A weighted average cost of capital (WACC) is estimated by analyzing the relative costs of equity and debt, using market data to capture investors' risk and return expectations",
      image: {
        src: "/images/screenshots/valuation/discountRate.png",
        alt: "Screenshot of discount rate estimation",
        positionRight: true,
      },
      ctaButton: false,
    },

    {
      title: "Exit value",
      description:
        "Since most businesses are expected to operate beyond the explicit forecast period - it's essential to estimate the exit value. This can be done using an exit multiple or a perpetuity growth model ",
      image: {
        src: "/images/screenshots/valuation/exitValue.png",
        alt: "Screenshot of exit value",
        positionRight: false,
      },
      ctaButton: false,
    },
  ],
  // faq: [
  //   {
  //     question: "What is a discount rate?",
  //     answer:
  //       "A discount rate is the interest rate used to discount future cash flows to their present value, reflecting the time value of money and investment risk.",
  //   },
  //   {
  //     question: "Why use DCF and multiples?",
  //     answer:
  //       "Business valuation is subjective, and it's important to use various methods to triangulate the value as a sanity check. DCF and multiples offer different perspectives and help ensure accuracy.",
  //   },
  //   {
  //     question:
  //       "What is the difference between enterprise value and equity value?",
  //     answer:
  //       "Enterprise value is the total value of a business, including debt and excluding cash. Equity value is the value of the shareholders' interest in the company, calculated by subtracting debt from enterprise value.",
  //   },
  //   {
  //     question: "What reference data is used to determine multiple valuation?",
  //     answer:
  //       "Actual and forward-looking multiples related to your specific industry are used.",
  //   },
  // ],
};

export const platform = {
  forecast: forecastPlatform,
  valuation: valuationPlatform,
};
